import React from "react"
import {
    AppProvider,
    Button,
    Layout,
    Page,
    Text,
} from "@shopify/polaris";
import { v4 as uuidv4 } from 'uuid'; 
import { Crisp } from "crisp-sdk-web";

class Help extends React.Component {

    componentDidMount() {
        const queryParams = window.location.search
        const urlParams = new URLSearchParams(queryParams)
        const shop = urlParams.get('shop')
        if (shop) {
                Crisp.configure("d706d890-199d-4990-b295-9d23ebbaf88a", {
                    autoload: false
                });
                Crisp.setTokenId(uuidv4());
                Crisp.session.setData({
                    company: shop
                });
                Crisp.load();
            }        
    }

    

    render() {
        return (
            <AppProvider
                apiKey={process.env.GATSBY_SHOPIFY_APP_API_KEY}
                forceRedirect={false}
            >
                <Page>
                    <Layout>
                        <Layout.Section>
                            <Text variant="headingXl" as="p">Somthing went wrong...</Text>
                            <br />
                            <Button variant="primary" onClick={() => {
                              if (window && window.$crisp) {
                                window.$crisp.push(["do", "chat:open"])
                              }
                            }}>Get help</Button>
                        </Layout.Section>
                    </Layout>
                </Page>
            </AppProvider>
        );
    }
}

export default Help
